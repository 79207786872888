
import { Component, Mixins, Prop } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { AuthMixin, AppHostnameMixin } from "@/mixins";
import TeamProfileCard from "@/components/profile/recruiting/TeamProfileCard.vue";

@Component({
	components: { 
		Page, 
		TeamProfileCard,
	}
})
export default class AthletePublicRecruitingProfileView extends Mixins(AuthMixin, AppHostnameMixin) {
	@Prop({ type: String, default: undefined }) teamId;
	@Prop({ type: String, default: undefined }) sharingUrlId;

	created() {
		// if the user is logged in, open the sharing link directly
		if( this.AuthIsReady ) {
			this.openCoachLink(this.TeamSharingLink);
		}
	}

	get TeamSharingLink(): string {
		return `teams/${this.teamId}/${this.sharingUrlId}`;
	}

}
