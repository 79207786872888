
import { Component, Mixins } from "vue-property-decorator";
import Page from "./Page.vue";

import { VuetifyMixin } from "@/mixins";

@Component({
	components: {
		Page,
	}
})
export default class PublicDashboard extends Mixins(VuetifyMixin) {
}
