
import { Component, Mixins, Prop } from "vue-property-decorator";
import { AppHostnameMixin, AuthMixin } from "@/mixins";
import Page from "@/views/Page.vue";
import RecruitingProfileCard from "@/components/profile/recruiting/RecruitingProfileCard.vue";

import { athleteApi } from "@/api/AthleteApi";
import { AthleteProfileModel } from "@/models";
import { notificationStore, sportStore } from "@/store";

@Component({
	components: { Page, RecruitingProfileCard }
})
export default class AthletePublicRecruitingProfileView extends Mixins(AuthMixin, AppHostnameMixin) {
	@Prop({ type: String, default: undefined }) athleteId;
	@Prop({ type: String, default: undefined }) sharingUrlId;

	created() {
		// if the user is logged in, open the sharing link directly
		if( this.AuthIsReady ) {
			this.openCoachLink(this.AthleteSharingLink);
		} else {
			this.loadAthlete();
		}
	}

	get AthleteSharingLink(): string {
		return `athlete/${this.athleteId}/${this.sharingUrlId}`;
	}

	athleteProfile: AthleteProfileModel = undefined;
	isProfileLoaded: boolean = false;
	get ProfileLoading() {
		return !this.isProfileLoaded || !this.athleteProfile || !this.athleteProfile.id;
	}
	async loadAthlete() {
		this.isProfileLoaded = false;
		try {
			await sportStore.loadSports();
			this.athleteProfile = await athleteApi.getPublicProfile(this.athleteId);
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Unable to load Athlete Profile. ${e}`});
		} finally {
			this.isProfileLoaded = true;
		}
	}
}
